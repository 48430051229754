import React from 'react'
import { Link as ScrollLink } from 'react-scroll'

const Banner = props => (
  <section id="banner">
    <div className="content">
      <header>
        <h2>Bienvenue à la Palette Sartoise</h2>
        <p>
          Le Club de Tennis de Table de Sart-Bernard.
        </p>
          <p>
          Matricule N188 de la province de Namur.
        </p>
          <p>
          Depuis 1987.
        </p>
      </header>
    </div>
  
    <ScrollLink
      to="one"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Banner
