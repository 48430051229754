import React from 'react'
import pic04 from '../images/pingpong-sartoise.jpg'

import Fade from 'react-reveal/Fade'

const Three = props => (
  <section
    id="three"
    className="spotlight style3 left inactive"
    style={{ backgroundImage: `url(${pic04})` }}
  >
    <span className="image fit main bottom">
      <img src={pic04} alt="" />
    </span>
    <Fade left big>
      <div className="content">
        <header>
          <h2>Le site Web de la Palette Sartoise</h2>
        </header>
        <p>
        En 1999, la Palette Sartoise publie son premier site internet, des œuvres de Philippe BOUNAMEAU, avant de subir un relifting complet en 2002, opéré par Stéphane DRAUX.<br/>
        Depuis 2009, le site a connu trois nouvelles versions des œuvres de l'actuel webmaster Jonathan ROUSSEAUX.
        </p>
      </div>
    </Fade>

  </section>
)

export default Three
