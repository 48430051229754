import React from 'react'
import pic03 from '../images/raquette-sartoise.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'
import Image from '../components/Image'

const Two = props => (
  <section
    id="two"
    className="spotlight style2 right inactive"
    style={{ backgroundImage: `url(${pic03})` }}
  >
    <span className="image fit main">
	<Image alt="Raquette - Tennis de table" filename="raquette-sartoise.jpg" />

    </span>
    <Fade right big>
      <div className="content">
        <header>
          <h3>Les années "Francis"</h3>
        </header>
        <p>
	  Francis EVRARD reprit la présidence du club en 1992, et l'assura jusqu'en 2008. <br/>
          Le secrétariat fut assuré par Pierre GABRIEL de 1994 à 1996, et ensuite reprit par Alain BOHON en 1997 puis par Gérard SIMON.<br/>
          La trésorerie fut gérée par Bernard ROELANDTS de 1994 à 1996, Damien EVRARD de 1997 à 2000 et Francis EVRARD de 2001 à 2007.<br/>
	</p>
        <header>
          <h3>Exil provisoire</h3>
        </header>
        <p>
	  Le début des années 2000 fut marqué par un exil d'une année et demi à ASSESSE pour la rénovation de notre local.<br/>
	  L'année 2002 marqua une année importante pour le club qui réintègra son local rue Morimont; local rénové et nettement plus adapté à la pratique du Tennis de Table 
        </p>
        <header>
          <h3>L'époque contemporaine</h3>
        </header>
        <p>
	  Depuis 2009, la présidence est assurée par Stéphane DRAUX.<br/>
	  En plus du président, le comité actuel se compose d'Alain BOHON, responsable interclubs et d'Olivier CAPELLE, secrétaire.<br/><br/><br/>
        </p>
      </div>
    </Fade>
    <ScrollLink
      to="three"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)

export default Two
