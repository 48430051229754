import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import One from '../components/One'
import Two from '../components/Two'
import Three from '../components/Three'


class Home extends React.Component {
  render() {
    return (
      <Layout location="/">
        <Helmet
          htmlAttributes={{ lang: 'fr' }}
          title="Palette Sartoise - Sart-Bernard"
          meta={[
            { name: 'description', content: 'Palette Sartoise - le club de tennis de table de Sart-Bernard' },
            { name: 'keywords', content: 'sart-bernard, tennis de table, pingpong' },
          ]}
        ></Helmet>
        <Banner />
        <One />
        <Two />
        <Three />
      </Layout>
    )
  }
}

export default Home
