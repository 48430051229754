import React from 'react'
import pic02 from '../images/table-sartoise.jpg'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const One = props => (
  <section
    id="one"
    className="spotlight style1 bottom inactive"
    style={{ backgroundImage: `url(${pic02})` }}
  >
    <span className="image fit main">
      <img src={pic02} alt="" />
    </span>
    <Fade bottom big>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-4 col-12-medium">
              <header>
                <h2>Histoire de la Palette Sartoise</h2>
              </header>
            </div>
            <div className="col-4 col-12-medium">
              <h3>Fondation en 1987</h3>
                <p>
                La <b>Palette Sartoise</b> fut fondée en 1987 par Jacques GERARD (Président) et André WOLF (Trésorier). <br/>
                C'est Jean-Claude CABU qui trouva un local au club (l'actuel: Rue Morimont) et fit don du matériel nécessaire pour le plaisir d'une dizaine de joueurs inscrits.
                </p>
            </div>
            <div className="col-4 col-12-medium">
              <p>
                Au début des années 90, la présidence fut assurée par Marc DEMOULIN et le secrétariat par Luc MARTIN (1990 et 1991).<br/>
                A cette époque, le club comptait 4 équipes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
    <ScrollLink
      to="two"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={50}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)
export default One
